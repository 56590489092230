.chatBotImageRootContainer {
    position: fixed;
    z-index: 10;
    bottom: 45px;
    right: 20px;
}

.chatBotImageContainer {
    /* box-shadow: 0px 0px 3px 0px #000000;
    border-radius: 14px 14px 14px 14px;
    background-color: var(--primary-color-dark-1);
    height: 50px;
    width: 50px;
    padding: 10px;
    display: flex; */

    /* box-shadow: 0px 0px 3px 1px #000000; */
    border-radius: 50%;
    background-color: #6a6a6a;
    height: 60px;
    width: 55px;
    display: flex;
}


.chatBotMessageRootContainer {
    max-width: 95vw;
    position: fixed;
    z-index: 10;
    bottom: 50px;
    right: 20px;
}

.chatBotMessageContainer {
    border-radius: 14px 14px 14px 14px;
    box-shadow: 0px 0px 3px 0px #000000;
    height: 100%;
    width: 100%;
}

.chatBotHeaderRootContainer {
    background-color: var(--primary-color-dark-1);
    border-radius: 14px 14px 0px 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px 5px 10px;
}

.botHeaderImage {
    height: 30px;
    width: 30px;
}

.chatBotHeaderName {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
}

.chatBotHeaderSubTitleName{
    font-size: 12px;
    font-weight: lighter;
    font-family: 'Quicksand';
    color: #eee;
}

.chatBotHeaderIconContainer {
    display: flex;
    gap: 10px;
}

.chatBotHeaderIcon {
    color: #ffffff;
    font-size: 20px;
    cursor: pointer;
}

.tabContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color-dark-1);
    gap: 10px;
    padding: 0px 10px 0px 10px;
}

.tabItem {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    padding: 3px 0px 3px 0px;
    color: #ffffff;
    font-weight: bold;
}

.activeTab {
    background-color: #ffffff;
    color: var(--primary-color-dark-1);
    border-radius: 14px 14px 0px 0px;
}

.tabItemContainer {
    max-height: calc(100vh - 200px);
    width: 100%;
    background-color: white;
    border-radius: 0px 0px 14px 14px;
}


.shareRootContainer {
    max-width: 90vw;
    width: 500px;
    background-color: white;
    border-radius: 14px 14px 14px 14px;
    box-shadow: 0px 0px 3px 0px #000000;
    padding: 10px;
}

.shareHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
}

.shareImageContainer {
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.shareImage {
    width: 100%;
    height: auto;
}

.shareNameContainerTitle {
    font-size: 20px;
    font-weight: bold;
}

.shareNameContainerSubTitle {
    font-size: 12px;
    font-weight: lighter;
    font-family: 'Quicksand';
}

.shareHeaderButtonContainer {
    font-size: 25px;
}

.shareBodyMailFormContainer {
    padding: 10px;
    max-height: calc(100vh - 300px);
    overflow-y: auto;
}

.shareBodyMailFormLabel {
    font-size: 14px;
    font-weight: bold;
}

.shareBodyMailFormInput {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: none;
}

.shareBodyMailFormInput:focus-visible {
    outline: none;
}

.shareBodyMailSearchContainer {
    background-color: #ffffff;
    position: absolute;
    margin-left: 5px;
    box-shadow: 0px 3px 5px gray, -5px 3px 8px gray, 5px 3px 8px gray;
    border-radius: 0px 0px 8px 8px;
    min-width: 150px;
    padding: 5px;
}

.userSearchItem {
    display: flex;
    gap: 5px;
    padding: 5px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.userSearchItem:hover {
    background-color: var(--gray-color-5);
}

.username {
    font-size: var(--font-size-7);
    font-weight: 600;
    color: var(--gray-color-2);
}

.usermail {
    font-size: var(--font-size-8);
    padding: 2px 10px 2px 10px;
    background-color: var(--gray-color-3);
    color: var(--gray-color-6);
    border-radius: 4px;
}






























.slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes slideInUp {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        visibility: visible;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slideInUp {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        visibility: visible;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}


.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }
}

.shareBodyButtonFormContainer {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-top: 5px;
}

.shareBodyButtonFormContainer > button {
    height: 24px!important;
}

.chatMesgRootContainer {
    display: flex;
    gap: 3px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.chatMesgRootContainerBot {
    direction: ltr;
}

.chatMesgRootContainerUser {
    direction: rtl;
}

.cbmBotImageContainer {
    height: 25px;
    width: 25px;
    border-radius: 10%;
}

.cbmBotImageContainer>img {
    width: 25px;
    height: auto;
}

.cbmMessage {
    border-radius: 5px;
    border: 1px solid var(--gray-color-4);
    padding: 10px;
    text-align: justify;
    color: #000000;
    direction: ltr;
   
}

.cbmMessageBot {
    font-family: 'Quicksand', sans-serif;
}

.shareBodyUsersContainer {
    width: 100%;
    border: 1px solid #eeeeee;
    border-radius: 3px;
    padding: 5px;
}

.chatWindowPreview {
    width: 100%;
    border: 1px solid #eeeeee;
    border-radius: 3px;
    padding: 5px;
}