.Lable
{
    background: red;
}

.navcontainer{
    background:blue;
    box-shadow: 0 5px 24px -10px #000 !important;
    color:black;
    flex-direction:row !important;
    height:60px;
    padding: 1.99rem!important;
}
.landingbackground
{
    background:#cfd3db;
    min-height: 100vh;
}

.landingbackgroundbody
{
    /*background:#cfd3db;*/
    min-height: 78vh;
    padding-top: 4%;
    opacity: 1;
    background: #EFF6F8 0% 0% no-repeat padding-box;
}

.landingbackgroundbodylandingdown
{
    
    /* background: #EFF6F8 0% 0% no-repeat padding-box; */
    height: auto;
    padding-bottom:1.5em;
}

.landingupbackground
{
    background-image: url('../assets/header_background.svg');
    /* min-height: 78vh; */
    /* padding-top: 2%; */
    /* background-image:  url("../assets/banner.png") !important; */
    background-size: cover;
    /* background-color: #124377 !important; */
    /* background-image: linear-gradient(360deg, #2262A5 0%, #124377 100%),
                  url("../assets/banner.png") !important; */
    /* background-image: linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5)),
    url("../assets/banner.png"); */
    /* background: transparent linear-gradient(360deg, #2262A5 0%, #124377 100%) 0% 0% no-repeat padding-box; */
    position: relative;
    box-shadow: 0px 3px 8px #25252576 !important;
    /* position: absolute;
    top: 50px;
    bottom: 30px;
    right: 0;
    left: 0;
    overflow-y: auto; */
}

.lbltxt
{
    color: #81B1FF;
    font-weight: 600;
}

.submit_btn
{
    width: 120px;
    max-width: 120px;
    border-radius: 20px;
    font-weight: 500px !important;
    background:#2E6FD8 !important;
    font-size: 14px;
}
.submit_btn:hover{
    background-color: #48a0d4!important;
    border-color: #ABBAC3;

}


.headerbackground
{
    /* background-image: url(../assets/header_background.png);
    position: relative;
    box-shadow: 0 5px 24px -10px #000 !important; */
    position: relative;
    background: transparent linear-gradient(359deg, #2262A5 0%, #124377 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #25252576;
    opacity: 0.9;
    width: 100%;
    /* height: 106px; */
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    
    
}
.headermenufont {
    color: white;
}

.activeTab {
    border-bottom : 3px #F7DC6F solid;
}

.headermenufont:hover { color: rgb(202, 200, 200) !important; }

.Landing .Header .headerbackground .navbarmenu{
    display: none;
    /* color:#48a0d4; */
}

.headernamesmall {
    color: white;
}
.Landing .Header .headerbackground .headernamesmall{
    display: none;
    /* color:#48a0d4; */
}


.boxbody
{
    position: relative;
    /*box-shadow: 0 5px 24px -10px #746d6d !important;*/
    width: 100%;
    height: 10rem;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    background:#f2f2f2;
    border-radius: 15px;
}
.boxbody:hover {
    box-shadow: #bbb5b5 0px 0px 10px 0px;
}
.headerbackgroundfont
{
    font-size: calc(20px + 2vmin);
    font-weight: 500;
    line-height: 1.2;
}

.righttext
{
    color: #fff !important;
    /* text-align: right; */
    font-weight: 300;
    font-size: 11px ;
    line-height: 1;
    /* font-family: Helvetica Neue !important; */
}

.textformat
{
    font-weight: 400;
    font-size: 14px ;
    line-height: 1;
    color: white;
}
.footerrighttextformat
{
    color:#acb2b5 !important;
}
.cardtextformat{
    font-weight: 600;
    font-size: 14px ;
    line-height: 1;
    color: #252525;
    text-align: center;
}


.cardwidth
{
    width: 18rem;
}

.footerbackground
{
    background-color: black;
    opacity: 1;
    border-top: 1px solid #DDD;
}

.boximagebtn
{
    margin: 15px 0 25px 0 !important;
    height: 53px;
    width: 60px;
    border-radius: 4px;
    margin: 15px 0 10px 0;
    background: #5298e0 !important;
    background: linear-gradient(to bottom, #5298e0 5%, #2a5c99 100%) !important;
    box-shadow: #777474 1px 2px 9px 1px;
    color: white;
    padding: 15px;
    display: inline-block;
}
.landingheader1 {
    font-weight: 200;
    font-size: 25px;
    line-height: 1;
    color: #ffff;
  }
  .w-75{
    width: 75%;
  }
  .highlightDPC{
    font-weight: 600;
  }
  .detailsDPC{
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color:white;
  }
  .detailsPara{
    /* margin-left: 1em; */
  }
  @media only screen and (min-width: 768px) and (max-width: 979px) {
    .mobilelandinggrid {
      padding-bottom: 1.5rem;
    }
    .mobilrtextsizeheader {
      font-size: calc(3px + 2vmin) !important;
    }
    .mob-text-right {
      text-align: right !important;
    }
    /* .mobiletempadd {top:83px !important} */
    .divcenterpage {
      width: 87% !important;
    }
    .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .mobilrdrop {
      text-align: center !important;
    }
    html body .container-fluid.body-content {
      position: absolute;
      top: auto;
      bottom: auto;
      right: 0;
      left: 0;
      overflow-y: auto;
      /* height: 85%;  */
    }
  }
  @media only screen and (min-width: 980px) and (max-width: 1180px) {
    .mobilelandinggrid {
      padding-bottom: 1.5rem;
    }
    .mobilrtextsizeheader {
      font-size: calc(3px + 2vmin) !important;
    }
    .mob-text-right {
      text-align: right !important;
    }
    /* .mobiletempadd {top:83px !important} */
    .divcenterpage {
      width: 87% !important;
    }
    .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .mobilrdrop {
      text-align: center !important;
    }
  
    html body .container-fluid.body-content {
      position: absolute;
      top: auto;
      bottom: auto;
      right: 0;
      left: 0;
      overflow-y: auto;
      /* height: 85%;  */
    }
  }
  .mobilelandinggrid {
    padding-bottom: 0.3rem;
   
  }
 
  
.borderbuttonlanding {
    border-radius: 20px;
    width: 120px;
    max-width: 120px;
    border-radius: 20px;
    font-weight: 500px !important;
    font-size: 14px;
    background-color: #2262a5;
    border-color: #2262a5;
    color: #ffffff;
  }
  .borderbuttonlanding:hover {
    border-radius: 20px;
    font-weight: 500px !important;
    font-size: 14px;
    background-color: #48a0d4 !important;
    border-color: #48a0d4;
    color: #ffffff;
  }
  .landboxbody:hover {
    box-shadow: #bbb5b5 0px 0px 10px 0px;
    text-decoration: none !important;
   
  }
  .landboxbody{
    height: 270px;
  }
  .mediumtextformatlanding {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    text-align: center;
    /* line-height: 1.5; */
  }
  .mediumtextformat {
    font-size: 14px;
    font-weight: 400;
  }
  
  .menu-style {
    /* font-family: 'Myriad Pro'; */
    font-size: 13.98px;
    font-weight: 400;
  }
  
  .name-txt {
    color: #ffffff !important;
    text-align: center;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  
  @media only screen and (max-width: 480px) {
    /* your CSS here */
    html body .container-fluid.body-content {
      position: absolute;
      top: auto;
      bottom: auto;
      right: 0;
      left: 0;
      overflow-y: auto;
    }
    .mobilrdrop {
      text-align: center !important;
    }
    .mobileview {
      display: block;
    }
    .desktopview {
      display: none;
    }
    .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .mobilelandinggrid {
      padding-bottom: 1.5rem;
    }
    .divcenterpage {
      width: 87% !important;
    }
    .mob-text-center {
      text-align: center !important;
    }
    .mobilrtextsizeheader {
      font-size: calc(3px + 2vmin) !important;
    }
    /* .mobiletempadd {top:83px !important} */
  }
  .imgheight {
    height: 9vh;
  }
  a.hover{
    text-decoration: none !important; 
  }
  /* .fixHeight{
    min-height: calc(100vh - 120px);
  } */
.subDetail{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: white;
}
.rightBorder{
    border-right: 2px solid #fff;
}
.fixOpenButtonPosition{
   /* position: fixed; */
}
.footerVertcalBorder{
  border-right: 1px solid black;

}

.newlandingbox
{
  background-color: #eeeeee;
  border: 1px solid #cccccc !important;
  transform-style: preserve-3d;
  transition: transform 0.7s;
  aspect-ratio: 1/1;
  perspective: 1000px;
  margin: 0px auto 40px auto;
  border-radius: 15px;
  cursor: pointer;
}

.newlandingboximg
{
  height:-webkit-fill-available;
}
.newlandingtitle
{
  border-bottom: 4px solid red;
  width: 50%;
  display: block;
}
.cardBacknewlanding
{
  color: var(--gray-color-6);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 10px;
  position: absolute;
  /* width: 97%; */
  width:100%;
  height: 100%;
  z-index: 9;
  bottom: 0;
  background: #3f50ecd3;
  /* background: #6c757d; */
  transition: all 0.5s ease;
  opacity: .5;
  display: none;
}
.newlandingbox:hover .cardBacknewlanding {

  border-radius: 15px;
  /* background: linear-gradient(180deg, rgba(1, 35, 90, 1) 0%, #024f99 50%, #0063c3 100%); */
  display: block;
  padding: 1.25rem;
  

}
/* .newlandingbox:hover .newlandingtitle
{
  display:none
} */
/* .newlandingbox:hover .newlandingboximg1
{
  display:none
} */

.newlandingtitlehover
{
  border-bottom: 4px solid red;
  width: 50%;
  display: block;
}
























