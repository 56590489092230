.Lable
{
    background: red;
}

.navcontainer{
    background:blue;
    box-shadow: 0 5px 24px -10px #000 !important;
    color:black;
    flex-direction:row !important;
    height:60px;
    padding: 1.99rem!important;
}
.landingbackground
{
    background:#cfd3db;
    min-height: 100vh;
}

.landingbackgroundbody
{
    /*background:#cfd3db;*/
    min-height: 78vh;
    padding-top: 4%;
    opacity: 1;
    background: #EFF6F8 0% 0% no-repeat padding-box;
}

.landingbackgroundbodylandingdown
{
    
    background: #EFF6F8 0% 0% no-repeat padding-box;
    height: auto;
}

.landingupbackground
{
    
    /* min-height: 78vh; */
    padding-top: 2%;
    background-image:  url("../assets/banner.png") !important;
    background-size: cover;
    background-color: #124377 !important;
    /* background-image: linear-gradient(360deg, #2262A5 0%, #124377 100%),
                  url("../assets/banner.png") !important; */
    /* background-image: linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5)),
    url("../assets/banner.png"); */
    /* background: transparent linear-gradient(360deg, #2262A5 0%, #124377 100%) 0% 0% no-repeat padding-box; */
    position: relative;
    box-shadow: 0px 3px 8px #25252576 !important;
    /* position: absolute;
    top: 50px;
    bottom: 30px;
    right: 0;
    left: 0;
    overflow-y: auto; */
}

.lbltxt
{
    color: #81B1FF;
    font-weight: 600;
}

.submit_btn
{
    width: 120px;
    max-width: 120px;
    border-radius: 20px;
    font-weight: 500px !important;
    background:#2E6FD8 !important;
    font-size: 14px;
}
.submit_btn:hover{
    background-color: #48a0d4!important;
    border-color: #ABBAC3;

}


.headerbackground
{
    /* background-image: url(../assets/header_background.png);
    position: relative;
    box-shadow: 0 5px 24px -10px #000 !important; */
    position: relative;
    background: transparent linear-gradient(359deg, #2262A5 0%, #124377 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #25252576;
    opacity: 0.9;
    width: 100%;
    /* height: 106px; */
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    
    
}
.headermenufont {
    color: white;
}

.activeTab {
    border-bottom : 3px #F7DC6F solid;
}

.headermenufont:hover { color: rgb(202, 200, 200) !important; }

.Landing .Header .headerbackground .navbarmenu{
    display: none;
    /* color:#48a0d4; */
}

.headernamesmall {
    color: white;
}
.Landing .Header .headerbackground .headernamesmall{
    display: none;
    /* color:#48a0d4; */
}


.boxbody
{
    position: relative;
    /*box-shadow: 0 5px 24px -10px #746d6d !important;*/
    width: 100%;
    height: 10rem;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    background:#f2f2f2;
    border-radius: 15px;
}
.boxbody:hover {
    box-shadow: #bbb5b5 0px 0px 10px 0px;
}
.headerbackgroundfont
{
    font-size: calc(20px + 2vmin);
    font-weight: 500;
    line-height: 1.2;
}

.righttext
{
    color: #fff !important;
    /* text-align: right; */
    font-weight: 300;
    font-size: 11px ;
    line-height: 1;
    /* font-family: Helvetica Neue !important; */
}

.textformat
{
    font-weight: 400;
    font-size: 13px ;
    line-height: 1;
}
.footerrighttextformat
{
    color:#acb2b5 !important;
}
.cardtextformat{
    font-weight: 600;
    font-size: 15px ;
    line-height: 1;
    color: #252525;
    text-align: center;
}


.cardwidth
{
    width: 18rem;
}

.footerbackground
{
    background-color: white;
    opacity: 1;
    border-top: 1px solid #DDD;
}

.boximagebtn
{
    margin: 15px 0 25px 0 !important;
    height: 53px;
    width: 60px;
    border-radius: 4px;
    margin: 15px 0 10px 0;
    background: #5298e0 !important;
    background: linear-gradient(to bottom, #5298e0 5%, #2a5c99 100%) !important;
    box-shadow: #777474 1px 2px 9px 1px;
    color: white;
    padding: 15px;
    display: inline-block;
}

