.rootContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 1000;
    background-color: #00000050;
}
.bodyWrapper{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bodyContainer {
    min-width: 50px;
    min-height: 50px;
}