.downloadButton{
border-radius: 0em 0.25em 0.25em 0em  !important;
}
.col-20{
    width: calc(100% / 5);
  }
  .cardStyling{
    min-height: 150px !important;
    border-radius: 20px !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
  }
  .text-blue{
    color: #007bff !important;
    cursor: pointer;
  }
  .img-height{
    height: 150px;
  }
  .img-height-upload{
    height: 135px;
  }