.amgen-blue {
  /* background-color: #002144 !important; */
  /* background-image: linear-gradient(to right,#002144,#2262a5,#2262a5,#002144); */
  background-image: url('../assets/TopBanner.svg');
  /* opacity: 0.7; */
  align-items: center;
}
.logo-height {
  max-height: 9vh;
}
.text-white {
  color: white;
}
.tour {
  display: inline-block;
  text-align: center;
  color: white;
  cursor: pointer;
  width: 100%;
}
.usercircle {
  background-color: rgb(153, 179, 240);
  width: 26px;
  /* height: 25px; */
  border-radius: 100%;
  /* margin-left: auto !important;
  margin-right: auto; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}
header {
  position: sticky;
  top: 0;
  z-index: 9999;
  /* box-shadow: 0px 3px 6px #2525255a; */
}
.dropdown-toggle::after {
  display: none;
}
/* .takeATour{
    padding-left: 8em;
  } */
.v-line {
  border-right: 2px solid white;
}
.headerFont {
  font-size: 2.3vh;
  padding-top: 2.7vh;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}
@media (min-width: 768px) and (max-width: 1024px){ 
  .headerFont {
    font-size: 1.7vh;
    padding-top: 3.2vh;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
  }
} 
.bimHeader {
  padding-left: 3.5em;
}
.takeATour {
  padding-right:2em;
  font-weight: 100;
}
.dropdown-menu {
  min-width: 15em !important;
  margin: 0.125rem 0 0 -30px !important;
 font-weight: 500;
}
.verticalBorder{
  border-left: solid 1px white;
  margin-top: 23px;
  margin-bottom: 23px;
}
.verticalBorderRightBanner{
  border-left: solid 1px rgb(160,160,255);
  margin-top: 4px;
  margin-bottom: 4px;
}
.selectedActiveTab {
  border-bottom: 0.125rem solid white;
  color: white;
  /* pointer-events: none */
}
.tabElement{
  padding-bottom: 18px;
}
.nameInDropdown{
  font-weight: 400;
}
.cursor_pointer{
  cursor: pointer;
}
.text-12{
  font-size: 12px;
}
.hasMouseEvent{
  cursor: pointer;
}
.text-13{
  font-size: 13px;
}
.userNameInsideDrop{
  padding-left: 2em;
}
.text-10{
  font-size: 10px;
}
.text-11{
  font-size: 11px;
}
.text-18{
  font-size: 18px;
}
.userbox{
  background-color: #007bff;
  width: 40px;
  /* height: 25px; */
  border-radius: 5px;
  /* margin-left: auto !important;
  margin-right: auto; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  box-shadow: 0px 3px 6px #2525255a;
}
