.addtemplatebackgroundbody
{
    /*background:#cfd3db;*/
    min-height: 78vh;
    padding-top: 3%;
}

.mandetoryfield
{
    margin-right: 8px;
    color: #f50202;
    font-size:14px !important;
    
}
