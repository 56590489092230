.rootContainer {
    height: 100%;
    width: 100%;
    border-radius: 0px 0px 14px 14px;
    display: flex;
    flex-direction: column;
}

.chatRootContainer {
    height: 100%;
    overflow-y: auto;
    /* background: url('./../botBlue.svg');
    background-repeat: no-repeat;
    background-size: 50% auto;
    background-position: center; */
}

.controlRootContainer {
    display: flex;
    border-top: 2px solid var(--primary-color-dark-1);
    padding: 10px;
    gap: 10px;
}

.inputControl {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    resize: none;
    background-color: var(--gray-color-5)
}

.inputControl:focus {
    outline: none;
}

.buttonSave {
    background-color: var(--gray-color-3) !important;
    color: white !important;
    cursor: pointer;
    font-size: 21px;
}


.chatMesgRootContainer {
    display: flex;
    gap: 3px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.chatMesgRootContainerBot {
    direction: ltr;
}

.chatMesgRootContainerUser {
    direction: rtl;
}

.cbmBotImageContainer {
    height: 25px;
    width: 25px;
    border-radius: 10%;
}

.cbmBotImageContainer>img {
    width: 25px;
    height: auto;
}

.cbmMessage {
    border-radius: 5px;
    border: 1px solid var(--gray-color-4);
    padding: 10px;
    text-align: justify;
    color: #000000;
    direction: ltr;
    font-size: 13px;
}

.cbmMessageBot {
    font-family: 'Quicksand', sans-serif;
}

.buttonFocus {
    height: 1px;
    width: 1px;
    background-color: transparent;
    border: none;
    outline: none;
}

.buttonFocus:focus {
    outline: none;
}


.loader {
    display: block;
    position: relative;
    height: 20px;
    width: 140px;
    background-image:
        linear-gradient(var(--primary-color-2) 20px, transparent 0),
        linear-gradient(var(--primary-color-2) 20px, transparent 0),
        linear-gradient(var(--primary-color-2) 20px, transparent 0),
        linear-gradient(var(--primary-color-2) 20px, transparent 0);
    background-repeat: no-repeat;
    background-size: 20px auto;
    background-position: 0 0, 40px 0, 80px 0, 120px 0;
    animation: pgfill 1s linear infinite;
}

@keyframes pgfill {
    0% {
        background-image: linear-gradient(var(--primary-color-2) 20px, transparent 0), linear-gradient(var(--primary-color-2) 20px, transparent 0), linear-gradient(var(--primary-color-2) 20px, transparent 0), linear-gradient(var(--primary-color-2) 20px, transparent 0);
    }

    25% {
        background-image: linear-gradient(var(--primary-color-dark-1) 20px, transparent 0), linear-gradient(var(--primary-color-2) 20px, transparent 0), linear-gradient(var(--primary-color-2) 20px, transparent 0), linear-gradient(var(--primary-color-2) 20px, transparent 0);
    }

    50% {
        background-image: linear-gradient(var(--primary-color-dark-1) 20px, transparent 0), linear-gradient(var(--primary-color-dark-1) 20px, transparent 0), linear-gradient(var(--primary-color-2) 20px, transparent 0), linear-gradient(var(--primary-color-2) 20px, transparent 0);
    }

    75% {
        background-image: linear-gradient(var(--primary-color-dark-1) 20px, transparent 0), linear-gradient(var(--primary-color-dark-1) 20px, transparent 0), linear-gradient(var(--primary-color-dark-1) 20px, transparent 0), linear-gradient(var(--primary-color-2) 20px, transparent 0);
    }

    100% {
        background-image: linear-gradient(var(--primary-color-dark-1) 20px, transparent 0), linear-gradient(var(--primary-color-dark-1) 20px, transparent 0), linear-gradient(var(--primary-color-dark-1) 20px, transparent 0), linear-gradient(var(--primary-color-dark-1) 20px, transparent 0);
    }
}