.insideVisialisation
{
    
    border-radius: 5px;
    box-shadow: #bbb5b5 0px 0px 10px 0px;
    /* background-color: #f4f4f4; */
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
}
.landboxbodyviz
{
  height: 200px;
}
.searchBoxvisualisation {
    /* border: .5px solid #ccc; */
    border-radius: 6px;
    /* width: 40%; */
  }
  .bodyvisul
  {
    overflow-y: scroll;
    max-height: 146px;
    font-size: 12px;
    overflow-x: hidden;
  }
  /* .btn.btn-white
  {
    border-width: 1px;
    background-color: #FFF !important;
    text-shadow: none !important;
  }
  .btn-white.btn-primary{
    border-color: #8aafce;
    color: #6688a6 !important;
  } */
  .btn-mini
  {
    float: right;
    border-color: #8aafce;
    color: #6688a6 !important;
    background-color: #fff;
    height: 20px;
    /* border-radius: 4px; */
    padding: 0px 5px;
    border-width: 2px;
    font-size: 12px;
    line-height: 1.5;
  }
  .btn-mini:hover
  {
    background-color: #8aafce;
    color: #fff !important;
  }

  .viz-filter-main
  {
    height: 100%;
    overflow: auto;
    width: 350px;
    /* padding-left: 10px; */
    float: left;
    border-right: 1px solid #aaa;
  }
  .viz-chart-main
  {
    height: 100%;
    overflow: auto;
    width: calc(100% - 350px);
    float: left;
  }
  .tab-content_vix
  {
    border: 1px solid #C5D0DC;
    background-color: #f4f4f4;
    /* border-top: none; */
    /* padding: 0px 12px; */
    position: relative;

  }
  .tab-content_vix_filter
  {
    border-bottom: 1px solid #C5D0DC;
    /* border-top: 1px solid #C5D0DC;
    font-weight: 500;
    font-size: 17px; */
    /* border-top: none; */
  }
  .advancefilterst
  {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  }
  .advancefilterst:hover
  {
    background-color: #e0dfdf;
  }
  .viz-option
  {
    border-bottom: 1px solid #aaa;
   
}
  

.calenderwrap{
  display: inline-block;
  position: relative;
}

.calenderelement
{
  position:absolute;
  /* left:50%; */
  top:40px;
  border:1px solid #ccc;
  z-index: 999;
  transform: translate(-10%, -113%);
  /* right:0; */
}