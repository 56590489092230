.linkdec
{
    font-weight: 500;
    color: #000;
}
.active {
    border-bottom : 3px #2723c3 solid;
}
.novborder
{
    border-bottom : 2px #ccc solid;
}
.approvalbackground
{
    background-color: #e4e8ed;
    
}
.filedata
{
    border-radius: 0!important;
    background-color: #FFF;
    border: 1px solid #c4bdbd;
}
.insideApproval
{
    background-color: white;
    border-radius: 12px;
    box-shadow: #bbb5b5 0px 0px 10px 0px;
}
.right-border
{
    border-right: 2px solid #ccc;
}

.submit_btnapp
{
    width: 120px;
    max-width: 120px;
    border-radius: 20px;
    font-weight: 500px !important;
    background:#2E6FD8 !important;
    font-size: 14px;
}
.submit_btnapp:hover{
    background-color: #48a0d4!important;
    border-color: #ABBAC3;
}










































  .dataheading
{
    font-weight: 600;
    color: #2262A5;
    text-align: left;
    font-size: 16px;
    /* border-bottom: 1px solid #d9cece; */
}
.datatext
{
  font-weight: 600;
  color: #020a13;
  text-align: left;
  font-size: 16px;
}
.submit-trigger
{
    /* font-weight: 600; */
    background-color: #2262A5;
    font-size: 16px; 
    border-radius: 12px;
}

.agGridWrapper .datatablerefresh .ag-header-cell {
    /* border-right: 1px solid #2262A5; */
    border-right: none;
    font-weight: 600;
    color: #2d2f31;
    font-size: 13px;
    font-family: 'roboto';
  }
  .agGridWrapper .datatablerefresh .ag-header-cell:last-child {
    border-right: none;
  }
  .agGridWrapper .datatablerefresh .ag-header-cell .ag-header-cell-comp-wrapper .ag-cell-label-container .ag-header-cell-text {
   text-align: center;
   
  }
  /* .ag-header-cell-resize::after
  {
    background-color:
  } */
  .agGridWrapper .datatablerefresh .ag-cell-not-inline-editing
  {
    box-shadow: 3px 3px 3px  #f5ebeb;
  }
  .agGridWrapper .datatablerefresh .ag-cell-value :first-child{
    box-shadow:none
  }
  .ag-aria-description-container
  {
    display: none;
  }

  .agGridWrapper .datatablerefresh .ag-cell-value {
    /* border-right: 1px solid #f5ebeb; */
     /* box-shadow: 3px 3px 3px  #f5ebeb; */
     /* padding-top: 1.3%; */
      padding-top: 1px;
  }
  .agGridWrapper .datatablerefresh .ag-root-wrapper.ag-ltr.ag-layout-normal {
    border: none;
    /* border: 1px solid #2262A5; */
    /* padding: 12px; */
    border-radius: 8px;
    opacity: 1;
    /* color: #EFF6F8;
    background: #EFF6F8 0% 0% no-repeat padding-box; */
  }
  .agGridWrapper .datatablerefresh .datastatus .ag-root-wrapper.ag-ltr.ag-layout-normal 
  {
    padding: 12px;
  }
  .agGridWrapper .datatablerefresh .ag-row-even,
  .agGridWrapper .datatablerefresh .ag-row-odd {
    overflow: hidden;
    border: 1px solid #f0eded;
    /* box-shadow: 3px 3px 3px  #c5c4c45a; */
    /* box-shadow: inset 2px 2px 4px #f0eded, 3px 3px 5px #f0eded; 
     */
    border-radius: 15px;
    background: #fff !important;
    /* margin-top:12px; */
    height: 45px !important;
    --ag-row-height: calc(var(--ag-grid-size) * 5) !important;
   
    /* height: 70px !important; */
    /* height: 90px !important; */
    /* transform: translateY(2px) !important; */
    text-align: left;
    font-weight: 500;
    font-size: 13px;
    color: #181d1f;
  }
  .agGridWrapper .datatablerefresh .ag-paging-panel {
    border-top: none;
    height: 32px;
    color: #858585;
  }
  .agGridWrapper .datatablerefresh .ag-body.ag-layout-normal {
    margin-top: 7px;
  }
  input.filterInput {
    /* position: relative; */
    bottom: 38px;
    left: 65%;
    width: 30%;
    outline: none; 
    /* border: none;
    border-bottom: 1px solid #d9cece; */
    border: 1px solid #2262A5;
    border-radius: 6px;
  }

  .datatablerefresh .ag-theme-alpine  {
   
    /* --ag-foreground-color: rgb(126, 46, 132);
    --ag-background-color: rgb(249, 245, 227);
    --ag-header-foreground-color: rgb(204, 245, 172);
    --ag-header-background-color: #fff !important;
    --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
    --ag-header-column-resize-handle-color: rgb(126, 46, 132); */
  
    /* --ag-font-family: monospace; */
    --ag-font-size: 13px !important;
    /* --ag-font-family: monospace; */
    --ag-grid-size: 7.5px !important;
    --ag-header-background-color: #fff !important;
    --ag-icon-font-color:'blue' !important;

    --ag-header-height: calc(var(--ag-grid-size) * 3) !important;
    /* --ag-row-height: calc(var(--ag-grid-size) * 5) !important; */
    
    --ag-toggle-button-border-width:50% !important;
    --ag-toggle-button-width: 18px !important;
    --ag-selected-row-background-color: rgb(229 234 239 / 30%) !important;
    --ag-border-color:#f0eded !important;
    --ag-checkbox-checked-color: #858585 !important;
    --ag-range-selection-border-color:#f5ebeb !important;
    /* --ag-row-hover-color: #ffc107 !important; */
    /* --ag-alpine-active-color:#fff !important   */
   /*text color*/
   
    --ag-foreground-color: #181d1f !important;
    
    
    /* --ag-alpine-active-color:#fff !important */
   
  }

       
  .dataheadingrefresh
  {
    font-weight: 600;
    color: #2262A5;
    text-align: left;
    /* font-size: 23px; */
    font-size: 20px;
    border-bottom: 1px solid #d9cece;
  }
  .searchBox {
    border: .5px solid #ccc;
    border-radius: 6px;
    width: 40%;
  }
  
  .ag-center-cols-viewport
  {
    overflow-x:hidden 
  }

 .accordian
 {
  border: .5px solid rgba(0, 0, 0, 0.1);
    /* border-radius: 2px; */
 }
 .bodyscroll
 {
  display: block;
    flex: 1 1 auto;
    height: 430px;
    overflow-x: hidden;
    overflow-y: scroll;
 }
 .alert
 {
  padding: .35rem 1.00rem;
 }
 .ag-overlay-loading-center
 {
  border:none;
  box-shadow: none;
 }
 /* styles.css */
.align-right {
  display: flex;
  justify-content: flex-end;
}
 