body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.multiSelectContainer .searchWrapper
{
  border: none;
  padding: 0px;
  height: 50px;
  overflow: auto;
  
}

.loader {
  background-color:rgba(255,255,255);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
}
.loaderInside {
    position: fixed;
    border: 10px solid #f3f3f3;
    border-top: 10px solid #3498db;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    top:50%;
    left:50%;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 


  body::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #b8dcff;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background: #01234a;
  box-shadow: inset 0 0 3px #ccc;
}

body::-webkit-scrollbar-thumb:hover {
  background: rgb(39, 101, 163);
  box-shadow: inset 0 0 3px #ccc;
}
@media (min-width: 1400px) {
  body {
   overflow-y: hidden;
  }
}





