.input-group-text{
    border-radius:  0.25rem 0em 0em 0.25em !important;
    background-color: #2B63BD;
}
.form-control{
    border-radius: 0em 0.25em   0.25em 0em !important;
    font-size: 0.9rem !important;
}
input[type=file]::file-selector-button {
    /* border-radius: .2em; */
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    transition: 1s;
    height: 7vh;
    cursor: pointer;
  }
 
  #formFile{
    border-radius: 0em 0.25em   0.25em 0em !important;
    height: 7vh;
  }
.mandetoryfield{
    margin-left: 4px;
    color: #f50202;
    font-size:14px !important;
    
}
.select-template{
    border-radius: 0em 0.25em   0.25em 0em !important;
}
.labelPadding{
    padding-left: -15px;
}
/* .selectDropdown{
    &__menu {
        margin-top: 0px !important;
      }
} */
.selectDropdown__menu {
    margin-top: 2px !important;
}
.selectDropdown__menu-list{
    max-height: 200px !important;
}
.customLabelMargin{
    margin-bottom: 0.3em !important;
}
.close-toast-button{
    position: absolute;
  top: 3px;
  right: 5px;
  cursor: pointer;
  border: none;
  background: transparent;
  font-size: 20px;
  padding: 0px;
  line-height: 1;
  color: #333;
}
.errorBox{
    word-wrap: break-word;
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #fff;
    color: black;
    padding: 10px;
    border-radius: 5px;
    z-index:  10000;
    width:25%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
