  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }


  @media only screen and (max-width: 480px) {
    /* your CSS here */
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .h-100 {
    height: 100% !important;
  }

  .py-5 {
    padding-top: 6rem !important;
    padding-bottom: 3rem !important;
  }

  a {
    text-decoration: none;
  }

  .imgheight {
    height: 79px;
  }

  .divcenterpage {
    width: 50%;
    padding: 2%;
    margin: auto;
  }

  .globaltextformat {
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: #716e74;
  }

  .ps-6 {
    padding-left: 4rem !important;
  }

  .font500 {
    font-weight: 500;
    font-size: 13px;
  }

  .mobileview {
    display: none;
  }

  /* table {
    display: flex;
    flex-flow: column;
    width: 100%;
  }

  thead {
    flex: 0 0 auto;
  }

  tbody {
    flex: 1 1 auto;
    display: block;
  
    overflow-x: hidden;
    height: 230px; 
    overflow-y: scroll;
    display:block;
  } */
  #style-2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  #style-2::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }

  #style-2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: rgb(39, 126, 177);
  }

  #style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
  }

  #style-3::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
    -webkit-appearance: none;
    height: 6px;
  }

  #style-3::-webkit-scrollbar-thumb {
    background-color: rgb(39, 126, 177);
  }



  tr {
    width: 100%;
    display: table;
    table-layout: fixed;
  }

  th {
    position: sticky;
    top: 0;
  }




  /* .custom-scrollbar {
    position: relative;
    height: 300px;
    overflow: auto;
        table-layout: fixed;
        border-collapse: collapse;
    }
    .table-wrapper-scroll-y {
    display: block;
    } */



  /* .custom-scrollbar tbody {
      display: block;
      width: 100%;
      overflow: auto;
      height: 300px;
    }
    .custom-scrollbar thead tr {
      display: block;
    }
    
    .custom-scrollbar  td {
      padding: 5px 10px; 
      width: 230px; 
    }

    .custom-scrollbar th
    {
      height: 30px;
      width: 230px;
    }
    th {
      background: #ABDD93;
    } */

  /* table tbody, table thead
  {
      display: block;
  }

  table tbody 
  {
    overflow: auto;
    height: 300px;
  }

  table tr td{
      width: 230px; 
  }

  th
  {
      width: 300px;
  } */

  /* input[type="text"] {
    width: 100% !important;
    border-style: solid;
    border-width: 1px;
    border-color: #0063c3;
  } */

  .form-control {
    /*color: #788491;*/
    color: #5f6367;
    /*border-color: #00508b !important;*/
  }

  /* .input-group-text
  {
    border-color: #00508b !important;
  } */

  .input-group {
    color: #788491;
  }

  .form-control:focus,
  select:focus {
    color: #788491;
    /* border-color: #788491;
    box-shadow: inset 0 1px 1px #788491, 0 0 3px #788491; */
  }


  /* html {
    height: 100%;
  }
  html body {
    height: 100%;
    overflow: hidden;
  } */

  .before-edit {
    background-color: #ffffff !important;
    border-width: 0px !important;
    color: #716e74;
    font-size: 14px;
    font-weight: 400;
  }

  .submit-reset:hover {
    background-color: rgb(211, 53, 53) !important;
  }

  .submit-reset {
    width: 120px;
    max-width: 120px;
    border-radius: 20px;
  }

  .heading {
    color: #277eb1 !important
  }

  .ag-cell a.g-cell-not-inline-editing .ag-cell-normal-height .ag-cell-value {
    text-align: center;
  }

  html body .container-fluid.body-content {
    position: absolute;
    top: 50px;
    bottom: 30px;
    right: 0;
    left: 0;
    overflow-y: auto;
    /* height: 85%;  */
  }

  header {
    /* position: absolute; */
    left: 0;
    right: 0;
    top: 0;
    /* height: 50px; */
  }

  footer {
    /* position: absolute; */
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    position: fixed;
    z-index: 10;
  }

  .tabspace {
    margin-left: 10px;
    font-weight: 600;
  }

  .react-tabs__tab--selected {
    border-color: #9fadbb #9fadbb transparent !important;
    border-top: 2px solid #4C8FBD !important;
    background-color: #FFF !important;

    box-shadow: 0 -2px 3px 0 rgb(0 0 0 / 15%) !important;
    color: #576373 !important;
    font-size: 16px;

  }

  .react-tabs__tab {
    border-color: #C5D0DC;
    background-color: #eee6e6;
    color: #999;

  }

  .react-tabs__tab:hover {
    color: #428bca !important;
  }

  .datatable .ag-header {
    /* font-family: cursive; */
    background: #2262A5 0% 0% no-repeat padding-box;


  }

  /* .ag-theme-alpine .ag-header-group-cell {
    font-weight: normal;
    font-size: 22px;
  }*/
  /* .ag-cell a.g-cell-not-inline-editing .ag-cell-normal-height .ag-cell-value
  {
    text-align: center !important;
  } */
  .datatable .ag-header-cell {
    color: #e4e4e4;
  }

  /* .ag-cell-value
  {
    text-align: center;
  } */

  .datatable .ag-center-cols-container {
    font-weight: 500;
    color: #393939;
  }

  .datatable .ag-row-even {

    background-color: #fff;
  }

  .datatable .ag-row-odd {
    background-color: #eaf2f9;
  }

  .ag-row-odd {
    background-color: #f2f2f2 !important;
  }

  .ag-row-even {

    background-color: #fff;
  }

  .ag-cell-value .textarea {
    border-radius: 0 !important;
    color: #858585;
    background-color: #FFF;
    border: 1px solid #c4bdbd;
  }

  /* .datatable .ag-cell .ag-cell-not-inline-editing 
  {
    text-align: center !important;
  } */

  .statusview .ag-theme-alpine {
    /* font-family: Trebuchet MS,Tahoma,Verdana,Arial,sans-serif; */
    /* --ag-foreground-color: rgb(126, 46, 132);
    --ag-background-color: rgb(249, 245, 227);
    --ag-header-foreground-color: rgb(204, 245, 172);
    --ag-header-background-color: rgb(209, 64, 129);
    --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
    --ag-header-column-resize-handle-color: rgb(126, 46, 132); */

    --ag-font-size: 10px !important;
    /* --ag-font-family: monospace; */
    --ag-grid-size: 4px !important;
  }

  .templateaction .ag-theme-alpine {


    --ag-font-size: 10px !important;
    /* --ag-font-family: monospace; */
    --ag-grid-size: 4px !important;
  }

  /* .ag-theme-alpine .ag-header-cell-label {
    padding-left: 18px;
  } */

  /* .ag-header-cell-sorted-asc {
    color: blue;
    font-weight: bold;
  }

  .ag-header-cell-sorted-desc {
    color: red;
    font-weight: bold;
  } */
  .select-search-container {
    width: auto !important;
  }

  .modal-xl {
    --bs-modal-width: 1304px;
  }

  /* @media only screen and (max-width: 480px ) {
    
    .mobileview{display: block;}
    .desktopview{display: none;}
    .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .mobilrdrop
    {
      text-align: center !important;
    }
    .mobilelandinggrid
    {
      padding-bottom: 1.5rem;
    }
    .divcenterpage
    {
      width: 87% !important;
    }
    .mob-text-center
    {
      text-align: center !important;
    }
    .mobilrtextsizeheader
    {
      font-size: calc(3px + 2vmin) !important;
    }
    .mobiletempadd {top:83px !important}
    }

    

    @media only screen and  (min-width: 768px) and (max-width: 979px) {
    .mobilelandinggrid
    {
      padding-bottom: 1.5rem;
    }
    .mobilrdrop
    {
      text-align: center !important;
    }
    .mobilrtextsizeheader
    {
      font-size: calc(3px + 2vmin) !important;
    }
    .mob-text-right
    {
      text-align: right !important;
    }
    .mobiletempadd {top:83px !important}
    .divcenterpage
    {
      width: 87% !important;
    }
    .navbar-nav .dropdown-menu {
      position: absolute;
    }

    }


    @media only screen and (min-width: 980px)  and (max-width: 1180px){
      .mobilrdrop
      {
        text-align: center !important;
      }
      .mobilelandinggrid
      {
        padding-bottom: 1.5rem;
      }
      .mobilrtextsizeheader
      {
        font-size: calc(3px + 2vmin) !important;
      }
      .mob-text-right
      {
        text-align: right !important;
      }
      .mobiletempadd {top:83px !important}
      .divcenterpage
      {
        width: 87% !important;
      }
      .navbar-nav .dropdown-menu {
        position: absolute;
      }
      
      
      } */

  /*New DEsign*/

  .landingheader1 {
    font-weight: 500;
    font-size: 28px;
    line-height: 1;
    color: #FFFF
  }


  .borderbuttonlanding {
    border-radius: 20px;
    width: 120px;
    max-width: 120px;
    border-radius: 20px;
    font-weight: 500px !important;
    font-size: 14px;
    background-color: #2262A5;
    border-color: #2262A5;
    color: #FFFFFF;

  }

  .borderbuttonlanding:hover {
    border-radius: 20px;
    font-weight: 500px !important;
    font-size: 14px;
    background-color: #48a0d4 !important;
    border-color: #48a0d4;
    color: #FFFFFF;


  }

  .landboxbody:hover {
    box-shadow: #bbb5b5 0px 0px 10px 0px;
  }

  .mediumtextformatlanding {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    text-align: center;
    /* line-height: 1.5; */
  }

  .mediumtextformat {
    font-size: 14px;
    font-weight: 400;


  }

  .menu-style {
    /* font-family: 'Myriad Pro'; */
    font-size: 13.98px;
    font-weight: 400;
  }

  .name-txt {
    color: #FFFFFF !important;
    text-align: center;
    padding-right: 0px !important;
    padding-left: 0px !important;
    /* font-family: Helvetica Neue !important; */
    /* font: normal normal bold 16px/3px Helvetica Neue !important; */
  }

  @media only screen and (max-width: 480px) {

    /* your CSS here */
    html body .container-fluid.body-content {
      position: absolute;
      top: auto;
      bottom: auto;
      right: 0;
      left: 0;
      overflow-y: auto;
      /* height: 85%;  */
    }

    .mobilrdrop {
      text-align: center !important;
    }

    .mobileview {
      display: block;
    }

    .desktopview {
      display: none;
    }

    .navbar-nav .dropdown-menu {
      position: absolute;
    }

    .mobilelandinggrid {
      padding-bottom: 1.5rem;
    }

    .divcenterpage {
      width: 87% !important;
    }

    .mob-text-center {
      text-align: center !important;
    }

    .mobilrtextsizeheader {
      font-size: calc(3px + 2vmin) !important;
    }

    /* .mobiletempadd {top:83px !important} */
  }



  @media only screen and (min-width: 768px) and (max-width: 979px) {
    .mobilelandinggrid {
      padding-bottom: 1.5rem;
    }

    .mobilrtextsizeheader {
      font-size: calc(3px + 2vmin) !important;
    }

    .mob-text-right {
      text-align: right !important;
    }

    /* .mobiletempadd {top:83px !important} */
    .divcenterpage {
      width: 87% !important;
    }

    .navbar-nav .dropdown-menu {
      position: absolute;
    }

    .mobilrdrop {
      text-align: center !important;
    }

    html body .container-fluid.body-content {
      position: absolute;
      top: auto;
      bottom: auto;
      right: 0;
      left: 0;
      overflow-y: auto;
      /* height: 85%;  */
    }

  }


  @media only screen and (min-width: 980px) and (max-width: 1180px) {

    .mobilelandinggrid {
      padding-bottom: 1.5rem;
    }

    .mobilrtextsizeheader {
      font-size: calc(3px + 2vmin) !important;
    }

    .mob-text-right {
      text-align: right !important;
    }

    /* .mobiletempadd {top:83px !important} */
    .divcenterpage {
      width: 87% !important;
    }

    .navbar-nav .dropdown-menu {
      position: absolute;
    }

    .mobilrdrop {
      text-align: center !important;
    }

    html body .container-fluid.body-content {
      position: absolute;
      top: auto;
      bottom: auto;
      right: 0;
      left: 0;
      overflow-y: auto;
      /* height: 85%;  */
    }



  }

  @media only screen and (min-width: 980px) and (max-width: 1180px) {
  }

  .opacity {
    z-index: 1 !important;
    cursor: pointer !important;
  }

  .reactTooltip {
    z-index: 1 !important;
  }

  .font-size-12 {
    font-size: 12px;
  }

  .font-size-13 {
    font-size: 13px !important;
  }

  .modal-dialog-recenter {
    max-width: 1200px !important;
    margin: 5rem auto !important;
  }

  .modal-dialog-recenterfile {
    max-width: none;
  }


  .scrollbarp::-webkit-scrollbar {
    -webkit-appearance: none;
    /* height: 6px;  FOR HORIZONTAL SCROLL*/
    width: 6px;
    /* width of the entire scrollbar */
  }

  .scrollbarp::-webkit-scrollbar-track {
    background: #F5F5F5;
    /* color of the tracking area */
  }

  .scrollbarp::-webkit-scrollbar-thumb {
    background-color: #00000080;
    border-radius: 4px;
    box-shadow: 0 0 1px #ffffff80;
  }

  .scrollbarp::-webkit-scrollbar-thumb:hover {
    background-color: #70636380;

  }
  .accordion__panel
  {
    padding :0px !important
  }
  .screenBody{
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-image: url(./Components/assets/dpc_bg.png);
    background-size: cover;
  }
  :root {
    --primary-color-dark-1: #01234a;
    --primary-color-1: #0063c3;
    --primary-color-2: #469AEB;
    --primary-color-3: #B8DCFF;
    --Secondary-color-1: #024F99;
    --Secondary-color-2: #E9F4FF;
    --error-color-1: #C70039;
    --active-color-1: #fd9e02;
    --gray-color-1: #000000;
    --gray-color-2: #58595b;
    --gray-color-3: #9b9b9d;
    --gray-color-4: #cccccc;
    --gray-color-5: #eeeeee;
    --gray-color-6: #ffffff;
    --font-size-1: 25px;
    --font-size-2: 23px;
    --font-size-3: 20px;
    --font-size-4: 18px;
    --font-size-5: 16px;
    --font-size-6: 14px;
    --font-size-7: 12px;
    --font-size-8: 10px;
    --font-size-9: 8px;
}

/* CSS used here will be applied after bootstrap.css */






.notifications-wrapper {
   overflow:auto;
    max-height:260px;
  }
  
.menu-title {
   color:#ff7788;
   font-size:16px;
    display:inline-block;
    }


    
      
.dropdown-menu.divider {
margin:5px 0;          
}

.item-title {

font-size:16px;
color: #252525;
  
}

.notifications a.content {
text-decoration:none;
background:#e9e4e4;

}
  
.notification-item {
padding:10px;
margin:5px;
background:#e9e4e4;
border-radius:4px;
}

.autoSizeDiv
{
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}





