.disclaimer-portal{
  background-color: white;
  height: 30%;
  top: 40%;
  left:40%;
  width:50%;
  position: absolute;
  z-index: 1;
}

.logo-height-disclaimer{
  height: 70%;
}

.disLeftPanel{
  text-align: left;
  font-size: 1em;
  color:#2162A5;
}
.disclaimerBody{
color: var(--base-shade-70, rgba(0, 0, 0, 0.70));
/* font-size: 0.75em; */
font-weight: 500;
}
.closeDisclaimer{
  cursor: pointer;
}
.disclaimerPopup{
  z-index: 2;
}
.mousePointer{
  cursor: pointer;
}
.modalFooter{
  text-align: center;
  border-top: #8e8e8e 1px solid;
}
.declarationText{
  font-size: 0.80em;
  font-weight: 400;
}
/* input[type=checkbox] {
  vertical-align: middle;
  position: relative;
  bottom: 1px;
} */
#checkboxESign{
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}